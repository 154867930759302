import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { HelmetProvider } from "react-helmet-async"
import TagManager from "react-gtm-module"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import reportWebVitals from "./reportWebVitals"
import store, { persistor } from "./stores/store"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import App from "./App"

const tagManagerArgs = {
  gtmId: "G-R1T0CXBEJV",
}

TagManager.initialize(tagManagerArgs)

const container = document.getElementById("root")
const root = createRoot(container!)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
})

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <App />
            {process.env.REACT_APP_DEBUG_QUERIES === "true" && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
