import React, { useState, useEffect } from "react"
import { IonIcon } from "@ionic/react"
import { AxiosError } from "axios"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router"
import { type RootState } from "stores/reducers"
import FocusTrap from "focus-trap-react"
import "./MyProfile.scss"

import Skeleton from "react-loading-skeleton"
import { toggleErrorMessageAction } from "stores/actions/toastMessageActions"
import { toast } from "react-toastify"
import { deleteToken } from "api/logout"
import { logout } from "../api/helper"

import "react-loading-skeleton/dist/skeleton.css"

import { useWindowSize } from "../utils/hooks"
import trashOutline from "../theme/images/trash-outline.svg"
import close from "../theme/images/close-outline.svg"
import Input from "./Input"
import { type FormikType } from "../types/utils"
import { deleteUserAccount } from "../api/account"
import Loader from "./Loader"
import { useAccount, useProfileAddress } from "../hooks/user"
import Button from "./Button"

interface MyProfileType {
  formik: FormikType<any>
  classButton: string;
}

function MyProfile({ formik, classButton }: MyProfileType) {
  const { width } = useWindowSize()

  const landlineInput = React.useRef(null)
  const mobileInput = React.useRef(null)
  const mailInput = React.useRef(null)
  const passInput = React.useRef(null)

  const [deleteAccount, setDeleteAccount] = useState(false)

  const [deleteAccountConfirmed, setDeleteAccountConfirmed] = useState(false)
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const dark = useSelector((state: RootState) => state.theme.dark)
  const { data: user } = useAccount()
  const { data: profileAddress } = useProfileAddress()

  useEffect(() => {
    if (deleteAccount) {
      setDeleteAccount(false)
    }
  }, [pathname])

  const deleteUserAccountAction = async () => {
    setDeleteAccountConfirmed(true)
    await deleteUserAccount()
      .then((data: any) => {
        if (data.status === 200) {
          deleteToken().then(() => {
            logout()
            toast.success(data.data)
          })
        }
      })
      .catch((err: AxiosError) => {
        if (err?.response?.status === 401) {
          toast.error("Vous n'êtes pas autorisé à effectuer cette action.")
        } else if ((err.response?.data as any)?.error) {
          toast.error((err.response?.data as any).error)
        } else {
          dispatch<any>(toggleErrorMessageAction())
        }
        setDeleteAccountConfirmed(false)
        setDeleteAccount(false)
      })
  }

  const toastOnError = () => {
    if (Object.entries(formik.errors).length !== 0) {
      toast.error("Le formulaire est incorrect ou incomplet.")
    } else {
      formik.handleSubmit();
    }
  }

  return (
    <form onSubmit={formik.handleSubmit} title="Données personnelles">
      {deleteAccount && (
        <FocusTrap>
          <div
            className="infos-profile-container-modal-container"
            role="dialog"
            aria-modal="true"
            aria-label="Modale de suppression de compte."
          >
            <div className="infos-profile-container infos-profile-container--delete-section">
              <button
                type="button"
                className={`${dark ? "dark" : ""}`}
                aria-label="Fermer la modale"
                onClick={() => {
                  setDeleteAccount(false)
                }}
              >
                Fermer
              </button>
              <div className="my-profil-delete-section-text">
                <p>
                  <strong>
                    Souhaitez-vous <span>supprimer la totalité de vos données personnelles</span> contenues sur le site
                    Vilogia.fr ?
                  </strong>
                </p>
                <small>Si vous cliquez sur &quot;Supprimer&quot;, vos données seront définitivement effacées.</small>
              </div>
              <div className="my-profil-delete-section-buttons">
                <button
                  type="button"
                  aria-label="Confirmer la suppression de mon compte"
                  className={`delete-btn ${dark ? "" : "light"}`}
                  onClick={async () => {
                    await deleteUserAccountAction()
                  }}
                >
                  {!deleteAccountConfirmed ? (
                    <>
                      <IonIcon
                        icon={trashOutline}
                        aria-hidden="true"
                        style={{ fontSize: "1.3em", marginRight: "5px" }}
                      />
                      Supprimer
                    </>
                  ) : (
                    <Loader />
                  )}
                </button>
                <button
                  type="button"
                  aria-label="Annuler la suppression du compte"
                  className={`abort-btn ${dark ? "dark" : "light"}`}
                  onClick={() => {
                    setDeleteAccount(false)
                  }}
                >
                  <IonIcon
                    icon={close}
                    aria-hidden="true"
                    title="Fermer"
                    style={{ fontSize: "1.3em", marginRight: "5px" }}
                  />
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </FocusTrap>
      )}
      <div className="container_profile">
        <div className="">
          <h1 className="title_profile">Mon profil</h1>

          <section className="account_infos-profil card">
            <div className="infos-profile-container">
              <p>
                <span>N° de compte client</span>
              </p>
              <p>{user?.contract || <Skeleton />}</p>
            </div>
            <div className="infos-profile-container">
              <p>
                <span>Nom</span>
              </p>
              <p>{user?.lastName || <Skeleton />}</p>
            </div>
            <div className="infos-profile-container">
              <p>
                <span>Prénom</span>
              </p>
              <p>{user?.firstName || <Skeleton />}</p>
            </div>
            <div className="infos-profile-container">
              <p>
                <span>Domicilié au</span>
              </p>
              <p>
                {profileAddress?.street || <Skeleton />}
                <br />
                {profileAddress?.zipcode || <Skeleton />} {profileAddress?.city || <Skeleton />}
              </p>
            </div>
            <div className="email_input-profile_container infos-profile-container">
              <p className="input-label_profile">
                N° de tel. fixe
                {width > 768 ? <br /> : null}
                <span className="my-profil_subtitle"> Facultatif</span>
              </p>
              <div className="container__input--profile">
                <Input
                  id="landlinePhone"
                  sr_only="Numéro de téléphone fixe"
                  title="N° de tel. fixe"
                  name="landlinePhone"
                  className="email_input-profile"
                  formik={formik}
                  modifier
                  innerRef={landlineInput}
                  ariaDescId="errorLandlinePhone"
                  ariaRequired={false}
                  autoComplete="tel-local"
                />
              </div>
            </div>
            <div className="email_input-profile_container infos-profile-container">
              <p className="input-label_profile">
                N° de tel. mobile
                {width > 768 ? <br /> : null}
                <span className="my-profil_subtitle"> Obligatoire</span>
              </p>
              <div className="container__input--profile">
                <Input
                  id="mobilePhone"
                  sr_only="Numéro de téléphone mobile"
                  title="N° de téléphone mobile"
                  name="mobilePhone"
                  className="email_input-profile"
                  formik={formik}
                  modifier
                  innerRef={mobileInput}
                  ariaDescId="errorMobilePhone"
                  ariaRequired
                  autoComplete="tel-local"
                />
              </div>
            </div>
            <div className="email_input-profile_container infos-profile-container">
              <p className="input-label_profile">
                Adresse mail
                {width > 768 ? <br /> : null}
                <span className="my-profil_subtitle"> Obligatoire</span>
              </p>
              <div className="container__input--profile">
                <Input
                  id="email"
                  sr_only="Adresse mail"
                  name="email"
                  className="email_input-profile"
                  formik={formik}
                  modifier
                  innerRef={mailInput}
                  title="Adresse mail"
                  ariaDescId="errorEmail"
                  ariaRequired
                  autoComplete="email"
                />
              </div>
            </div>
            <div className="password_input-profile_container infos-profile-container">
              <p className="input-label_profile">
                Mot de passe
                {width > 768 ? <br /> : null}
                <span className="my-profil_subtitle"> Obligatoire</span>
              </p>
              <div className="container__input--profile">
                <Input
                  id="password"
                  type="password"
                  sr_only="new-password"
                  name="password"
                  className="email_input-profile"
                  formik={formik}
                  modifier
                  innerRef={passInput}
                  title="Mot de passe"
                  ariaDescId="errorEmail"
                  ariaRequired
                  autoComplete="new-password"
                  passwordSecurity
                />
              </div>
            </div>
            <div className="infos-profile-container">
              <button
                type="button"
                title="Supprimer mon compte"
                aria-label="Cliquer pour supprimer le compte."
                className="my-profil-delete-btn"
                onClick={() => {
                  setDeleteAccount(true)
                }}
              >
                Je veux supprimer mon compte
              </button>
            </div>
            
            <div className={`submit-button_profil_container ${classButton}`}>
              <Button
                type="button"
                className="submit-button_profil"
                disabled={!formik.dirty || !formik.errors || formik.isSubmitting}
                value="Enregistrer"
                onClick={() => toastOnError()}
              >
                Enregistrer
              </Button>
              {formik.dirty || !formik.isValid ? (
                <button
                  type="button"
                  className="reset-button_profil"
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    formik.resetForm()
                  }}
                >
                  {" "}
                  Annuler mes modifications
                </button>
              ) : null}
            </div>
          </section>
        </div>
      </div>
    </form>
  )
}

export default MyProfile
