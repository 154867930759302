import { IonButton, IonImg } from "@ionic/react"
import "./AsideInfos.scss"
import { type AsideInfosType } from "../types/common"

interface PropsAsideInfos {
  asideInfos: AsideInfosType
}

function AsideInfos({ asideInfos }: PropsAsideInfos) {
  if (asideInfos.length === 0) {
    return null
  }

  return (
    <>
      {asideInfos.map((asideInfo) => (
        <div className="aside__container card" key={asideInfo.id}>
          <IonImg src={`${process.env.REACT_APP_BACKEND}/${asideInfo.pictoUrl}`} alt="" aria-hidden="true" />
          <div className="aside__text">
            <h2>{asideInfo.title}</h2>
            <p>{asideInfo.content}</p>
            {asideInfo.link && (
              <a className="more-infos" href={asideInfo.link} aria-label={asideInfo.linkLabel}>
                {asideInfo.linkLabel}
              </a>
            )}
            {asideInfo.linkButton && (
              <IonButton className="button__releve" href={asideInfo.linkButton}>
                {asideInfo.linkButtonLabel}
              </IonButton>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export default AsideInfos
