import { IonPage, IonContent, IonText } from "@ionic/react"
import React from "react"
import { useHistory, useLocation } from "react-router"
import { useFormik } from "formik"
import * as Yup from "yup"
import "./SignupCreate.scss"
import "./ResetPassword.scss"
import queryString from "query-string"
import { Helmet } from "react-helmet-async"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

import "@ionic/react/css/core.css"
import lock from "../../theme/images/icon_lock_password.svg"
import Container from "../../layouts/container/Container"
import BuildLayout from "../../layouts/BuildLayout"
import Input from "../../components/Input"

import Button from "../../components/Button"
import newPassword from "../../api/account"
import PasswordSecurityHelper from "../../components/PasswordSecurityHelper"

function ResetPassword() {
  const router = useHistory()
  const { pathname } = useLocation()
  const { recoveryCode } = queryString.parse(router?.location.search) as {
    recoveryCode?: string
  }

  const validationSchema: any = Yup.object().shape({
    password: Yup.string()
      .min(8, "Le mot de passe est trop court.")
      .max(50, "Le mot de passe est trop long.")
      .matches(
        /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[$&+,:;=?@#|<>.^*()%!-]).{8,}$/,
        "Votre mot de passe ne respecte pas le format demandé."
      )
      .required("Ce champs est obligatoire."),
    passwordConfirm: Yup.string()
      .required("Merci de confirmer votre mot de passe.")
      .test(
        "passwords-match",
        "Les mots de passe doivent être identiques.",
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (value: string) => formik.values.password === value
      ),
  })

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirm: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!recoveryCode) {
        // Handle error or redirect ?
        return
      }

      await newPassword(recoveryCode, values.password)
        .then(() => {
          router.push("/login", {
            message: "Mot de passe mis à jour avec succès.",
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
  })

  return (
    <>
      <Helmet>
        <title>Réinitialiser le mot de passe - Espace locataire - Vilogia</title>
        <meta name="description" content="Renseignez votre nouveau mot de passe." />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <IonPage>
        <Header />
        <IonContent role="main">
          <BuildLayout>
            <Container signup>
              <IonText>
                <h1>
                  <span className="h1-signup-create">Réinitialisez votre mot de passe.</span>
                  <br />
                  Complétez les champs suivants :
                </h1>
              </IonText>
              <form className="signup-create_inputs" onSubmit={formik.handleSubmit}>
                <div className="input-signup">
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    icon={lock}
                    placeholder="Votre nouveau mot de passe"
                    formik={formik}
                    title="Nouveau mot de passe"
                    autoComplete="new-password"
                  />
                </div>

                <PasswordSecurityHelper password={formik.values.password} />

                <Input
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  icon={lock}
                  placeholder="Confirmation du mot de passe"
                  formik={formik}
                  title="Confirmation du nouveau mot de passe"
                  autoComplete="new-password"
                />
                <div className="button-container">
                  <Button type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                    Je valide
                  </Button>
                </div>
              </form>
            </Container>
          </BuildLayout>
          <Footer />
        </IonContent>
      </IonPage>
    </>
  )
}

export default ResetPassword
