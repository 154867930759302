import "./ReturnButton.scss"
import React from "react"
import { IonRow, IonIcon } from "@ionic/react"
import { useHistory } from "react-router"
import arrowBackCircleOutline from "../theme/images/arrow-back-circle-outline.svg"
import Button from "./Button"

function ReturnButton() {
  const router = useHistory()

  const goBack = () => {
    router.goBack()
  }

  return (
    <IonRow>
      <Button className="dark-green back-button" onClick={goBack} buttonTitle="Retour à la page précédente">
        <IonIcon slot="start" icon={arrowBackCircleOutline} aria-hidden="true" name="" />
        Retour
      </Button>
    </IonRow>
  )
}

export default ReturnButton
