import { IonPage, IonContent, IonText, IonItem, IonModal } from "@ionic/react"
import React from "react"
import { useHistory, useLocation } from "react-router"
import { useFormik } from "formik"
import * as Yup from "yup"

import "./SignupCreate.scss"
import { Helmet } from "react-helmet-async"
import { useSelector } from "react-redux"
import { AxiosError } from "axios"
import Header from "../../components/Header"
import Footer from "../../components/Footer"

import "@ionic/react/css/core.css"
import lock from "../../theme/images/icon_lock_password.svg"
import messageIcon from "../../theme/images/icon_letter.svg"
import Container from "../../layouts/container/Container"
import BuildLayout from "../../layouts/BuildLayout"
import Input from "../../components/Input"

import Button from "../../components/Button"
import { createAccount } from "../../api/account"
import PasswordSecurityHelper from "../../components/PasswordSecurityHelper"
import Message from "../../components/Message"
import { useFormState } from "../../utils/hooks"
import type { RootState } from "../../stores/reducers"

const signupFormValuesDefault = {
  societyCode: "",
  accountNumber: "",
  birthday: "",
}

const isEmailDefault = {
  here: false,
  current: "",
}

function SignupCreate() {
  const dark = useSelector((state: RootState) => state.theme.dark)
  const { state, setstate, message, setmessage } = useFormState()
  const { pathname } = useLocation()

  const router = useHistory<{
    signupFormValues: {
      societyCode: string
      accountNumber: string
      birthday: string
    }
    isEmail: {
      here: boolean
      current: string
    }
  }>()

  const signupFormValues = router.location.state?.signupFormValues || signupFormValuesDefault
  const isEmail = router.location.state?.isEmail || isEmailDefault

  const validationSchema: any = Yup.object().shape({
    // https://github.com/jaredpalmer/formik/issues/243
    email: Yup.string()
      .email("Veuillez renseigner une adresse e-mail valide, en incluant le symbole '@'.")
      .required("Ce champ est obligatoire."),
    password: Yup.string()
      .min(8, "Le mot de passe est trop court.")
      .max(50, "Le mot de passe est trop long.")
      .matches(
        /^(?=.*[$&+,:;=?@#|<>.^*()%!-]).{8,}$/,
        "Au moins un caractère spécial est requis ($&+,:;=?@#|<>.^*()%!-)"
      )
      .matches(/^(?=.*\d).{8,}$/, "Au moins un caractère numérique est requis")
      .matches(/^(?=.*[^a-z]).{8,}$/, "Au moins un caractère minuscule est requis")
      .matches(/^(?=.*[A-Z])(?!.* ).{8,}$/, "Au moins un caractère majuscule est requis")
      .required("Ce champ est obligatoire"),
    passwordConfirm: Yup.string()
      .required("Merci de confirmer votre mot de passe.")
      .test(
        "passwords-match",
        "Les mots de passe doivent être identiques.",
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (value: any) => formik.values.password === value
      ),
  })

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      email: "",
      password: "",
      passwordConfirm: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async ({ email, password }) => {
      setstate("normal")

      await createAccount(
        signupFormValues.societyCode,
        signupFormValues.accountNumber,
        signupFormValues.birthday.replaceAll("/", "-"),
        email,
        password
      )
        .then(() => {
          router.push("/mail-confirmation")
        })
        .catch((err: AxiosError) => {
          setstate("error")

          switch (err.response?.status) {
            case 409:
              setmessage("Cette adresse email est déjà rattachée à un compte inscrit.")
              break
            case 400:
              setmessage("Le formulaire est incorrect.")

              if (undefined !== (err.response?.data as any)?.error) {
                setmessage((err.response?.data as any)?.error)
              }
              break
            default:
              setmessage("Une erreur innatendue est arrivée")
          }
        })
    },
  })

  return (
    <>
      <Helmet>
        <title>Création de compte, définition de ses identifiants de connexion - Espace Locataire - Vilogia</title>
        <meta name="description" content="Deuxième partie de la création de compte pour l'Espace Locataire Vilogia" />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <IonPage className="signup-create-page">
        <Header />
        <IonContent role="main">
          <BuildLayout>
            <Container signup>
              <IonText>
                <h1>
                  <span className="h1-signup-create">Bienvenue !</span>
                  <br />
                  Complétez les champs suivants :
                </h1>
              </IonText>
              {state === "success" ? <Message text={message} /> : null}
              {state === "error" ? <Message type="error" text={message} /> : null}
              <form className="signup-create_inputs" onSubmit={formik.handleSubmit}>
                <div className="input-signup">
                  <div id="emailSignupCreateLabelledBy">Adresse e-mail</div>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    icon={messageIcon}
                    placeholder="Votre adresse e-mail"
                    help="Il doit s'agir d'une adresse e-mail accessible, elle vous permettra de valider votre compte."
                    formik={formik}
                    title="Adresse e-mail"
                    ariaDescId="errorSignupCreateEmail"
                    ariaRequired
                    ariaLabelledby="emailSignupCreateLabelledBy"
                    autoComplete="email"
                  />
                  <div id="passwordSignupCreateLabelledBy">Mot de passe</div>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    icon={lock}
                    placeholder="Votre nouveau mot de passe"
                    formik={formik}
                    title="Mot de passe"
                    ariaDescId="errorSignupCreatePassword"
                    ariaRequired
                    ariaLabelledby="passwordSignupCreateLabelledBy"
                    autoComplete="new-password"
                  />
                </div>

                <PasswordSecurityHelper password={formik.values.password} />
                <div id="passwordConfirmSignupCreateLabelledBy">Confirmation du mot de passe</div>
                <Input
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  icon={lock}
                  placeholder="Confirmation du mot de passe"
                  formik={formik}
                  title="Confirmation du mot de passe"
                  ariaDescId="errorSignupCreatePasswordConfirm"
                  ariaRequired
                  ariaLabelledby="passwordConfirmSignupCreateLabelledBy"
                  autoComplete="new-password"
                />

                <div className="button-container">
                  <Button type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                    Je valide
                  </Button>
                </div>
              </form>
            </Container>
          </BuildLayout>
          <Footer />
        </IonContent>
        <IonModal
          className={`${dark ? "dark signup_IonModal" : "signup_IonModal"}`}
          isOpen={isEmail.here}
          onDidDismiss={() => {
            isEmail.here = false
          }}
        >
          <p>
            Voulez-vous utiliser cet e-mail ?
            <IonItem
              className="email_current"
              lines="none"
              detail={false}
              button
              onClick={() => {
                formik.setFieldValue("email", isEmail.current, false)
                isEmail.here = false
              }}
            >
              {isEmail.current}
            </IonItem>
            <IonItem
              className="email_current"
              lines="none"
              detail={false}
              button
              onClick={() => {
                formik.setFieldValue("email", "", false)
                isEmail.here = false
              }}
            >
              Je souhaite saisir une nouvelle adresse
            </IonItem>
          </p>
        </IonModal>
      </IonPage>
    </>
  )
}

export default SignupCreate
