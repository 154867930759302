import { Redirect, useLocation, useParams } from "react-router"
import "./NewArticle.scss"
import { connect } from "react-redux"
import { Helmet } from "react-helmet-async"
import ReturnButton from "components/ReturnButton"
import { IonSpinner } from "@ionic/react"
import * as actions from "../../stores/actions/newsActions"
import AuthLayout from "../../layouts/AuthLayout"
import Footer from "../../components/Footer"
import { useNewsDetails } from "../../hooks/news"

function NewArticle(props: any) {
  const { setModalNoNews, news } = props
  const { pathname } = useLocation()
  const { id }: any = useParams()
  const { data: newsData, isLoading } = useNewsDetails(id)

  if (!isLoading && !newsData) {
    if (!news.showModale) {
      setModalNoNews(true);
    }
    return (
      <Redirect to="/actualites" />
    )
  }

  return (
    <>
      <Helmet>
        <title>{newsData?.title ?? "Actualité"} - Espace locataire - Vilogia</title>
        <meta name="description" content="Ensemble des actualités en lien avec Vilogia." />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <AuthLayout>
        <div className="new_page">
          <div className="container-width--padded">
            <ReturnButton />
            {isLoading ? (
              <div className="spinner-container">
                <IonSpinner color="secondary" />
              </div>
            ) : (
              <>
                <h1 className="new_page_content">{newsData?.title}</h1>
                <div className="news__article--container">
                  {newsData?.visualWebPath ? (
                    <img
                      className="news__article__image"
                      src={`${process.env.REACT_APP_BACKEND}/${newsData.visualWebPath}`}
                      alt=""
                      aria-hidden="true"
                    />
                  ) : null}

                  {newsData?.description && (
                    <p
                      className="news__article__text"
                      dangerouslySetInnerHTML={{
                        __html: newsData.description,
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </AuthLayout>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  news: state.news,
})

const mapDispatchToProps = (dispatch: any) => ({
  setModalNoNews: (bool: boolean) => {
    dispatch(actions.setModalNoNews(bool))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(NewArticle)
