import { IonContent, IonPage, IonText, IonRouterLink, isPlatform, getPlatforms } from "@ionic/react"
import React, { useEffect, useState } from "react"
import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"
import { useHistory, useLocation } from "react-router"
import { useFormik } from "formik"
import * as Yup from "yup"
import { HashLink as Link } from "react-router-hash-link"
import queryString from "query-string"
import { Helmet } from "react-helmet-async"
import { handleKeyUp } from "utils/functions"
import { App } from "@capacitor/app"
import { useConfig } from "hooks/config"
import { login } from "../../api/auth"
import { useFormState, useRequireGuest } from "../../utils/hooks"
import { validateAccount } from "../../api/account"

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Container from "../../layouts/container/Container"
import Input from "../../components/Input"
import Message from "../../components/Message"
import BuildLayout from "../../layouts/BuildLayout"
import "./Login.scss"

import lock from "../../theme/images/lock-closed-outline.svg"
import messageIcon from "../../theme/images/icon_letter.svg"
import Button from "../../components/Button"
import MaintenanceApp from "../../components/MaintenanceApp"

export default function Login() {
  const router = useHistory<{ message: string; email?: string }>()
  const { state, setstate, message, setmessage } = useFormState()
  const { pathname } = useLocation()
  const { data: config } = useConfig()

  const [userEmail, setuserEmail] = useState("")
  const [platforms, setPlatforms] = useState<string[]>()
  const [appVersion, setAppVersion] = useState("")
  const [appBuild, setAppBuild] = useState("")

  async function getPlatformBuildIdentifiers() {
    try {
      const platformsArray: any[] = getPlatforms()
      setPlatforms(platformsArray[0])

      if (isPlatform("capacitor")) {
        const appInfos = await App.getInfo()
        setAppVersion(appInfos.version)
        setAppBuild(appInfos.build)
      } else {
        setAppVersion("desktop")
        setAppBuild("desktop")
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getPlatformBuildIdentifiers()
  }, [])

  useRequireGuest()

  const queryClient = useQueryClient()
  const processLogin = async (username: string, password: string) => {
    setstate("normal")
    await login(username, password, true, platforms, appVersion, appBuild, queryClient).catch((err: AxiosError) => {
      setstate("error")
      if (err.response?.status === 503) {
        setmessage("Le service est momentanément indisponible. Veuillez réessayer plus tard.")
      } else {
        setmessage("La combinaison email / mot de passe est incorrecte.")
      }
    })
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email("Veuillez renseigner une adresse e-mail valide, en incluant le symbole '@'")
      .required("Ce champ e-mail est obligatoire"),
    password: Yup.string().required("Ce champ mot de passe est obligatoire"),
  })

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      username: userEmail,
      password: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      await processLogin(values.username, values.password)
    },
  })

  useEffect(() => {
    formik.resetForm()
    if (router?.location?.state?.message) {
      setstate("success")
      setmessage(router.location.state.message)
      if (router?.location?.state?.email) {
        setuserEmail(router.location.state.email)
      }
    }
  }, [router.location])

  // Logique de validation de compte, si un code de validation est présent

  const { validationCode } = queryString.parse(router?.location.search) as {
    validationCode?: string
  }

  useEffect(() => {
    if (validationCode) {
      setstate("normal")
      validateAccount(validationCode)
        .then(() => {
          setstate("success")
          setmessage("Votre compte est désormais activé. Bienvenue dans votre Espace Locataire !")
        })
        .catch((err) => {
          const { response } = err
          if (response.status === 409) {
            setstate("info")
            setmessage(response.data?.error)
          } else {
            setstate("error")
            setmessage(response.data?.error)
          }
        })
    }
  }, [validationCode])

  return (
    <>
      <Helmet>
        <title>Connexion et création de compte - Espace locataire - Vilogia</title>
        <meta
          name="description"
          content="Accès à la connexion à l'espace locataire Vilogia et à la possibilité de créer un compte"
        />
        <link rel="canonical" href={pathname} />
      </Helmet>
      <IonPage>
        <Header />
        <IonContent id="content" role="main">
          <BuildLayout>
            <section className="banner_connect">
              <div className="banner_connect_container">
                {config?.banner ? (
                  <div className="banner_connect_content" dangerouslySetInnerHTML={{ __html: config.banner }} />
                ) : (
                  <p className="banner_connect_content">
                    Première connexion sur votre
                    <br />
                    Espace Locataire ? <br />
                    <Link
                      smooth
                      to="#scrollConnect"
                      className="create_scroll"
                      aria-label="Atteindre l'option de création de compte"
                      onKeyUp={(e) => {
                        handleKeyUp(e)
                      }}
                    >
                      Créez un compte dès maintenant.
                    </Link>
                  </p>
                )}
              </div>
            </section>

            {config?.maintenance ? (
              <Container connect>
                <MaintenanceApp message={config?.messageMaintenance} />
              </Container>
            ) : (
              <>
                <Container connect>
                  {/* TODO: à redéfinir */}
                  <h1 className="h1_page sr-only">Accés à l&apos;espace locataire</h1>
                  <IonText>
                    <h2 className="h1_page">
                      Je me connecte
                      <br />
                      <span className="h3_page">à mon Espace Locataire</span>
                    </h2>
                  </IonText>
                  {state === "success" ? (
                    <Message text={message} type={state as "success" | "error" | "info" | undefined} />
                  ) : null}
                  {state === "error" ? (
                    <Message text={message} type={state as "success" | "error" | "info" | undefined} />
                  ) : null}
                  <form onSubmit={formik.handleSubmit} className="login_form">
                    <div id="mailLoginLabelledBy">Adresse e-mail</div>
                    <Input
                      id="username"
                      icon={messageIcon}
                      placeholder="Votre adresse e-mail"
                      name="username"
                      inputmode="email"
                      help="
                Votre adresse mail n'est pas reconnue ? Créez votre compte.
                "
                      formik={formik}
                      title="Adresse e-mail"
                      ariaDescId="errorUsername"
                      ariaRequired
                      ariaLabelledby="mailLoginLabelledBy"
                      autoComplete="email"
                    />
                    <div id="passwordLoginLabelledBy">Mot de passe</div>
                    <Input
                      id="password"
                      icon={lock}
                      placeholder="Votre mot de passe"
                      type="password"
                      name="password"
                      formik={formik}
                      title="Mot de passe"
                      ariaDescId="errorPasswordLogin"
                      ariaRequired
                      ariaLabelledby="passwordLoginLabelledBy"
                      autoComplete="current-password"
                    />

                    <IonRouterLink
                      routerLink="/lost-password"
                      className="forgot"
                      aria-label="Lien vers la page de mot de passe oublié"
                    >
                      Mot de passe oublié ?
                    </IonRouterLink>

                    <Button
                      type="submit"
                      disabled={!formik.isValid || formik.isSubmitting}
                      buttonTitle="Bouton de connexion à votre Espace Locataire"
                    >
                      Je me connecte
                    </Button>
                  </form>
                </Container>
                <div id="scrollConnect" />
                <Container create>
                  <IonText>
                    <h2 className="h1_page">
                      Je crée <br />
                      <span className="h3_page">mon Espace Locataire</span>
                    </h2>
                  </IonText>
                  <IonText className="create">
                    <div className="create_text">
                      <p>
                        Votre Espace Locataire simplifie vos démarches. Consultez vos informations locatives. Retrouvez
                        vos documents, payez votre loyer en <span className="bold">toute simplicité.</span>
                      </p>
                    </div>
                  </IonText>
                  <Button
                    type="button"
                    className="connect_create"
                    onClick={() => {
                      router.push("/signup")
                    }}
                    buttonTitle="Bouton d'accés à la création de compte"
                  >
                    Je crée
                  </Button>
                </Container>
              </>
            )}
          </BuildLayout>
          <Footer />
        </IonContent>
      </IonPage>
    </>
  )
}
